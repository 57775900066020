import React from "react";
import { Container, useMediaQuery, Fade, Grow, Box } from "@mui/material";
import { useTheme } from "@mui/material";
import { StyleContext, StyleProvider } from "./contexts";
import { Text } from "./text";

export function ParagraphTitle({ color, center, fontWeight="bold", children, sx }) {
  return (
    <Grow in={true} timeout={1000}>
        <Text variant={"h4"} fontWeight={fontWeight} center={center} color={color} sx={{...sx, marginBottom:1}}>
        {children}
        </Text>
    </Grow>
  );
}

export function ParagraphBody({ color, center = false, children }) {
  return (
      <Text variant={"h5"} center={center} color={color}>
        {children}
      </Text>
  );
}

export function Paragraph({children }) {
  const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down("md"));
  const theme = useTheme();

  return (
    <>
        <Box>{children}</Box>
    </>
  );
}

/**
 * Converts an HTML string to a React component
 * @param {*} htmlString
 * @returns
 */
export function toHTML(htmlString) {
  return <div dangerouslySetInnerHTML={{ __html: htmlString }} />;
}
