import React, { createContext, useContext } from 'react';



function isTransparent(color) {
    if(color === 'transparent') return true;
    if(color === 'rgba(0, 0, 0, 0)') return true;
    if(color === 'rgba(0, 0, 0, 0.0)') return true;
    if(color === '#00000000') return true;
    // check if the alpha channel is 0 on the last two characters
    if (color && color.length === 9 && color.slice(7) === '00') return true;
    if( color && color.length === 5 && color.slice(4) === '0') return true;
    return false;
}

export const StyleContext = createContext({
    textColor: null,
    backgroundColor: null,
    getContrastText: function (theme, defaultColor = null) {
        if(this.textColor) 
            return this.textColor;
        this.backgroundColor = this.backgroundColor || theme.palette.background.default;
        if (
            this.backgroundColor === theme.palette.background.default ||
            this.backgroundColor === theme.palette.background.paper ||
            isTransparent(this.backgroundColor)
        ) {
            return defaultColor || theme.palette.text.primary;
        }
        return theme.palette.getContrastText(this.backgroundColor);
    },
});

export const StyleProvider = ({ value, children }) => {

    const context = useContext(StyleContext);
    const style = { ...context, ...value };

    return (
        <StyleContext.Provider value={style}>
            {children}
        </StyleContext.Provider>
    );
};