import React, { useRef, useState, useEffect } from "react";
import { IconButton, Slide, Box, Paper, Stack } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { useTheme } from "@mui/material";
import { StyleContext } from "./contexts";
import { useMediaQuery } from "@mui/material";

function getOppositeDirection(direction) {
  return direction === "left" ? "right" : "left";
}

export const Carousel = ({
  timeInterval,
  frameImageSrc,
  frameScale = 1,
  children,
}) => {
  const [activeIndex, setActiveIndex] = useState(0);
  const [nextIndex, setNextIndex] = useState(null);
  const [direction, setDirection] = useState("right");
  const [isExiting, setIsExiting] = useState(false);
  const containerRef = useRef(null);

  const theme = useTheme();
  const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down("md"));

  const handleNext = () => {
    setIsExiting(true);
    setNextIndex((activeIndex + 1) % children.length);
    setDirection("left");
  };

  const handlePrev = () => {
    setIsExiting(true);
    setNextIndex((activeIndex - 1 + children.length) % children.length);
    setDirection("right");
  };

  const handleExited = () => {
    setActiveIndex(nextIndex);
    setNextIndex(null);
    setIsExiting(false);
    setDirection(getOppositeDirection(direction));
  };

  useEffect(() => {
    if (timeInterval) {
      const automaticChangeInterval = setInterval(
        handleNext,
        timeInterval * 1000
      );
      return () => {
        clearInterval(automaticChangeInterval);
      };
    }
  }, [activeIndex]);

  const styleContext = React.useContext(StyleContext);

  const buttonBackgroundColor = theme.palette.primary.main + "22";

  const iconColor = styleContext.getContrastText(theme);

  return (
    <Box
      display="flex"
      flex={1}
      alignItems="center"
      flexDirection="column"
      sx={{
        position: "relative",
      }}
    >
      <Box
        display="flex"
        alignItems="center"
        flexDirection="column"
        style={{ overflow: "hidden" }}
      >
        {frameImageSrc && <Box
          flex={1}
          component="img"
          src={frameImageSrc}
          width={"100%"}
          sx={{
            position: "absolute",
            top: "50%", // Center vertically
            left: "50%", // Center horizontally
            transform: `translate(-50%, -50%) scale(${frameScale})`, // Center both horizontally and vertically with scale
            zIndex: -1,
          }}
        />}
        <Stack
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          width="100%"
          direction="row"
          spacing={"0.5rem"}
        >
          <IconButton
            variant="contained"
            onClick={handlePrev}
            style={{ backgroundColor: buttonBackgroundColor }}
            size={isSmallScreen ? "small" : "medium"}
          >
            <ArrowBackIcon sx={{ color: iconColor }} />
          </IconButton>
          <Box ref={containerRef} style={{ overflow: "hidden", padding:"1rem" }}>
            <Slide
              in={!isExiting}
              direction={direction}
              timeout={250}
              container={containerRef.current}
              onExited={handleExited}
            >
              <Box>{children[activeIndex]}</Box>
            </Slide>
          </Box>
          <IconButton
            variant="contained"
            onClick={handleNext}
            style={{ backgroundColor: buttonBackgroundColor }}
            size={isSmallScreen ? "small" : "medium"}
          >
            <ArrowForwardIcon sx={{ color: iconColor }} />
          </IconButton>
        </Stack>
        <Box display="flex" justifyContent="center" mt={2}>
          {children.map((_, index) => (
            <Paper
              key={index}
              sx={{
                width: 8,
                height: 8,
                borderRadius: "50%",
                mx: 1,
                backgroundColor:
                  index === activeIndex ? "primary.main" : "gray",
              }}
            />
          ))}
        </Box>
      </Box>
    </Box>
  );
};
