import { Card, Grow, Paper, Fade, Typography} from "@mui/material";
import { StyleContext, StyleProvider } from "./contexts";
import { useTheme } from "@emotion/react";
import React, { useState } from "react";
import { useMediaQuery } from "@mui/material";

export const CardContainer = ({ color, children }) => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down("md"));
  const backgroundColor = color ? color : theme.palette.background.paper;
  const [isHovered, setIsHovered] = useState(false);
  const styleContext = React.useContext(StyleContext);

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  return (
    <StyleProvider value={{ backgroundColor: backgroundColor }}>
        <Card
          elevation={20}
          sx={{
            p: "2rem",
            my:"1rem",
            borderRadius: "1rem",
            backgroundColor: backgroundColor,
            transition: "transform 0.2s ease-in-out, background-color 0.5s",
            transform: isHovered ? "scale(1.1)" : "scale(1)",
          }}
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
        >
          {children}
        </Card>
    </StyleProvider>
  );
};
