import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';



const defaultTheme = createTheme({
  palette: {
    contrastThreshold: 2,
  },
  typography: {
    fontSize: 14,
    fontWeightRegular: 400,
    fontFamily: [
    'Roboto', 'sans-serif'
    ].join(','),
  },
});




const jarsickTheme = createTheme({
  palette: {
    contrastThreshold: 2,
    primary: {
      main: '#A06BD5', // A purple color for primary elements
    },
    secondary: {
      main: '#39FF14', // A green color for secondary elements
    },
    error: {
      main: '#f44336', // A lighter red color for errors
    },
    background: {
      default: '#000000', // A dark background for elements like the app bar
      paper: '#222222', // A lighter green background for "paper" components like Card and Paper
    },
    text: {
      primary: '#ffffff', // A white text color
      secondary: '#757575', // A light grey text for secondary text
    },
  },
  typography: {
    fontSize:14,
    fontFamily: [
    'Kanit', 'sans-serif'
    ].join(','),
  },
});


ReactDOM.render(
  <React.StrictMode>
    <ThemeProvider theme={jarsickTheme}>
      <App />
    </ThemeProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

reportWebVitals();