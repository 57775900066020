import React from "react";
import Typography from "@mui/material/Typography";
import { Container, useMediaQuery, Fade, Grow, Slide } from "@mui/material";
import { useTheme } from "@mui/material";
import { StyleContext } from "./contexts"; 


const variants = ["h1", "h2", "h3", "h4", "h5", "h6", "body1", "body2", "subtitle1", "subtitle2",];

function getSmallerVariant(variant){
    const index = variants.indexOf(variant);
    if (index === -1) return variant;
    return index < variants.length - 1 ? variants[index + 1] : variant;
}
 
export const Text = React.forwardRef(function (props, ref){
    let { color, variant="body1", fontWeight="normal", margin=0, center, children, sx} = props;
   
    const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down("md"));
    const theme = useTheme();

   const styleContext = React.useContext(StyleContext);


    color = color? color : styleContext.getContrastText(theme);


    return (
        <Fade in={true} timeout={1000}>
            <Typography
                {...props}
                ref={ref}
                variant={isSmallScreen ? getSmallerVariant(variant) : variant}
                //fontSize={isSmallScreen ? "4rem" : "6rem"}
                fontWeight={fontWeight}
                align={center ? "center" : "left"}
                color={color}
                my={isSmallScreen ? margin/4: margin}
                sx={{...sx, transition: "color 0.5s" }}
            >
                {children}
            </Typography>
        </Fade>
    );
}
);