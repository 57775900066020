import React from "react";
import {
  Container,
  Typography,
  useMediaQuery,
  Fade,
  Divider,
} from "@mui/material";
import { useTheme } from "@mui/material";
import { Stack } from "@mui/material";

export const Footer = ({ color, children }) => {
  const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down("md"));
  const theme = useTheme();

  const backgroundColor = color || theme.palette.primary.dark;
  const textColor = theme.palette.getContrastText(backgroundColor);

  return (
    <Container
      sx={{ backgroundColor: backgroundColor, py: 0 }}
      maxWidth={false}
    >
      <Stack direction="column" spacing={0} alignItems="center">
        <Divider
          color={textColor}
          orientation="horizontal"
          variant="middle"
          flexItem
          sx={{ my: 1 }}
        />
        <Fade in={true} timeout={1000}>
          <Typography
            sx={{ mb: isSmallScreen ? "0.5rem" : "0.5rem" }}
            variant={isSmallScreen? "body2" : "body1"}
            align={isSmallScreen ? "center" : "center"}
            color={textColor}
          >
            {children}
          </Typography>
        </Fade>
      </Stack>
    </Container>
  );
};
