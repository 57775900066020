import React, { useEffect } from "react";
import {
  AppBar,
  Toolbar,
  IconButton,
  Typography,
  Button,
  Container,
  Drawer,
  List,
  ListItem,
  ListItemText,
  ListItemButton,
  Hidden,
  useMediaQuery,
  ListItemIcon,
  Divider,
  Icon,
  Box,
  Menu,
  MenuItem,
  Stack,
  Grid,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
//import { Link } from "react-router-dom";
import { useScrollTrigger, Slide } from "@mui/material";
import { useNavigate } from "react-router";
import { StyleContext, StyleProvider } from "./contexts";
import { useTheme } from "@mui/material";
import { useLocation } from "react-router";
import { useContext } from "react";
import { useRef, useState } from "react";
import { Row } from "./layout";
import { Text } from "./text";

export const NavbarTitle = (props) => {
  let { color, _drawer, _onNavigate, children, to } = props;

  const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down("md"));

  let variant = isSmallScreen ? "h4" : "h5";

  const icon = React.Children.toArray(children).find((child) => {
    return child.type === NavbarTitleIcon;
  });

  const drawerIcon = React.Children.toArray(children).find((child) => {
    return child.type === NavbarDrawerTitleIcon;
  });

  const content = React.Children.toArray(children).find((child) => {
    return (
      child.type !== NavbarTitleIcon && child.type !== NavbarDrawerTitleIcon
    );
  });

  const theme = useTheme();
  const styleContext = useContext(StyleContext);
  color = styleContext.getContrastText(theme);
  children = React.cloneElement(children, { sx: { color: color } });

  if (!_drawer) {
    return (
      <>
        <NavigationLink to={to}>
          <IconButton edge="start" color="inherit" aria-label="logo">
            {icon}
          </IconButton>
        </NavigationLink>
        <Typography
          variant={variant}
          fontWeight="bold"
          sx={{
            flexGrow: 1,
            textDecoration: "none",
            color: color,
          }}
        >
          {content}
        </Typography>
      </>
    );
  } else {
    return (
      <>
        <Stack
          direction="row"
          margin={2}
          spacing={2}
          alignContent={"center"}
          justifyContent={"center"}
          display={"flex"}
          alignItems={"center"}
        >
          <Text variant="h6" center>
            {content}
          </Text>
          <NavigationLink to={to}>
            <IconButton edge="start" color="inherit" aria-label="logo">
              {drawerIcon || icon}
            </IconButton>
          </NavigationLink>
        </Stack>
      </>
    );
  }
};

export const NavbarItem = ({
  to,
  color,
  _key,
  _onNavigate,
  _drawer,
  children,
}) => {
  const icon = React.Children.toArray(children).find((child) => {
    return child.type === NavbarItemIcon;
  });

  // remove the icon from the children keeping other children and text content
  const content = React.Children.toArray(children).filter((child) => {
    return (
      child.type !== NavbarItemIcon &&
      child.type !== NavbarDivider &&
      child.type !== NavbarItem
    );
  });

  // this is to create a list of sub items if the item has sub items to show in a sub popup menu
  const subItems = React.Children.toArray(children).filter((child) => {
    return child.type === NavbarItem || child.type === NavbarDivider;
  });

  const hasSubitems = subItems.length > 0;

  const theme = useTheme();
  const styleContext = useContext(StyleContext);
  color = styleContext.getContrastText(theme);

  const [anchorEl, setAnchorEl] = useState(null);

  const _onMenuClick = (event) => {
    if (subItems.length > 0) {
      setAnchorEl(event.currentTarget);
    }
  };

  const _onMenuClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    const handleScroll = () => {
      _onMenuClose();
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);


  const menu = hasSubitems ? (
    <Menu
      id={"appbar-menu-" + _key}
      anchorEl={anchorEl}
      
      MenuListProps={{
        //onMouseEnter: this.enterMenu,
        onMouseLeave: _onMenuClose,
        onScroll: _onMenuClose,
      }}

      anchorOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      keepMounted
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      open={Boolean(anchorEl)}
      onClose={_onMenuClose}
      onMouseLeave={_onMenuClose}
    >
      <StyleProvider
        value={{ backgroundColor: theme.palette.background.paper }}
      >
        {subItems.map((item, index) => {
          const element = React.cloneElement(item, {
            _onNavigate: _onNavigate,
            _drawer: true,
            _key: index,
          });
          return <>{element}</>;
        })}
      </StyleProvider>
    </Menu>
  ) : null;

  if (!_drawer) {
    return (
      <>
        <NavigationLink to={to} onClick={hasSubitems ? null : _onNavigate}>
          <Button
            color="inherit"
            style={{ marginLeft: "1rem", color: color }}
            key={_key}
            onClick={_onMenuClick}
          >
            {content}
          </Button>
        </NavigationLink>
        {icon}
        <Box sx={{ marginRight: "1rem" }} />
        {menu}
      </>
    );
  } else {
    return (
      <>
        <NavigationLink to={to}>
          <ListItem
            key={_key}
            disablePadding
            onClick={hasSubitems ? _onMenuClick : _onNavigate}
          >
            <ListItemButton>
              {icon && <ListItemIcon>{icon}</ListItemIcon>}
              {content}
            </ListItemButton>
          </ListItem>
        </NavigationLink>
        {menu}
      </>
    );
  }
};

export const NavbarItemIcon = ({ color, children }) => {
  const theme = useTheme();
  const styleContext = useContext(StyleContext);
  color = styleContext.getContrastText(theme);
  children = React.cloneElement(children, { sx: { color: color } });
  return <>{children}</>;
};

export const NavbarTitleIcon = ({ color, children }) => {
  const theme = useTheme();
  const styleContext = useContext(StyleContext);
  color = styleContext.getContrastText(theme);
  children = React.cloneElement(children, { sx: { color: color } });
  return <>{children}</>;
};

/**
 * Set an icon specifically for the drawer view (mobile view)
 * @param {*} param0
 * @returns
 */
export const NavbarDrawerTitleIcon = ({ color, children }) => {
  const theme = useTheme();
  const styleContext = useContext(StyleContext);
  color = styleContext.getContrastText(theme);
  children = React.cloneElement(children, { sx: { color: color } });
  return <>{children}</>;
};

export const NavbarDivider = ({ _drawer }) => {
  const theme = useTheme();
  const styleContext = useContext(StyleContext);
  const color = styleContext.getContrastText(theme);
  if (!_drawer) {
    return <Divider orientation="vertical" flexItem color={color} />;
  } else {
    return <Divider orientation="horizontal" sx={{ mt: "0rem" }} />;
  }
};

export const NavigationLink = ({
  to,
  onClick = null,
  onRender = false,
  children,
}) => {
  const navigate = useNavigate();
  const location = useLocation();

  const isExternalLink = to && to.startsWith("http");

  useEffect(() => {
    if (isExternalLink) return;
    if (location && location.hash && location.hash.length > 1) {
      const elementID = location.hash.substring(1);
      const element = document.getElementById(elementID);
      if (element) {
        if (onRender) {
          element.scrollIntoView();
        } else {
          element.scrollIntoView({ behavior: "smooth" });
        }
      }
    } else {
      if (onRender) {
        window.scrollTo(0, 0);
      } else {
        window.scrollTo({ top: 0, behavior: "smooth" });
      }
    }
  }, [location]);

  if (onRender) {
    navigate(to);
  }

  const onLinkClicked = () => {
    if(isExternalLink){
      window.open(to, "_blank");
    }else{
      to && navigate(to);
    }
    onClick && onClick();
  };

  return <Box onClick={onLinkClicked}>{children}</Box>;
};

export const Navbar = (props) => {
  const {
    color,
    textColor,
    elevation,
    fullWidth,
    height,
    sticky,
    fixed,
    children,
  } = props;

  const theme = useTheme();
  const backgroundColor = color ? color : theme.palette.primary.main;
  const drawerBakgroundColor = theme.palette.background.paper;

  const items = React.Children.toArray(children)
    .filter((child) => {
      return child.type === NavbarItem || child.type === NavbarDivider;
    })
    .map((child, index) => {
      return React.cloneElement(child, { _key: index });
    });

  const title = React.Children.toArray(children).find((child) => {
    return child.type === NavbarTitle;
  });

  const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down("md"));
  const [drawerOpen, setDrawerOpen] = React.useState(false);

  const handleDrawerOpen = () => setDrawerOpen(true);
  const handleDrawerClose = () => setDrawerOpen(false);

  const onDrawerLinkClick = (link) => {
    handleDrawerClose();
  };

  if (!title) {
    throw new Error("Navbar must have a title");
  }

  const defaultTitle = React.cloneElement(title, {
    _drawer: false,
  });
  const drawerTitle = React.cloneElement(title, { _drawer: true });

  const drawer = (
    <StyleProvider
      value={{ backgroundColor: drawerBakgroundColor, textColor: null }}
    >
      <Drawer
        anchor="left"
        open={drawerOpen}
        onClose={handleDrawerClose}
        color={drawerBakgroundColor}
      >
        <Box sx={{ mt: "1rem", display: "flex", justifyContent: "center" }}>
          {drawerTitle}
        </Box>
        <List style={{ marginTop: "1rem" }}>
          <Divider />
          {items.map((item, index) => {
            return React.cloneElement(item, {
              _drawer: true,
              _onNavigate: onDrawerLinkClick,
              _key: index,
            });
          })}
        </List>
      </Drawer>
    </StyleProvider>
  );

  return (
    <StyleProvider
      value={{ backgroundColor: backgroundColor, textColor: textColor }}
    >
      <HideOnScroll {...props} sticky={sticky} fixed={fixed}>
        <AppBar
          elevation={elevation}
          style={{
            backgroundColor: backgroundColor,
            transition: "background-color 0.5s",
          }}
          sx={{
            height: !isSmallScreen ? height : null,
            alignContent: "center",
            justifyContent: "center",
          }}
          position="fixed"
        >
          <Container maxWidth={fullWidth ? "xl" : "lg"}>
            <Toolbar>
              {defaultTitle}
              <Hidden mdUp>
                <IconButton
                  edge="start"
                  color="inherit"
                  aria-label="menu"
                  onClick={handleDrawerOpen}
                >
                  <MenuIcon sx={{ color: textColor }} />
                </IconButton>
                {drawer}
              </Hidden>
              <Hidden mdDown>
                {items.map((link, index) => {
                  return React.cloneElement(link, {
                    _drawer: false,
                    _key: index,
                  });
                })}
              </Hidden>
            </Toolbar>
          </Container>
        </AppBar>
      </HideOnScroll>
    </StyleProvider>
  );
};

function HideOnScroll(props) {
  const { sticky, fixed, children, window } = props;
  // Note that you normally won't need to set the window ref as useScrollTrigger
  // will default to     window.
  // This is only being set here because the demo is in an iframe.
  const trigger = useScrollTrigger({
    target: window ? window() : undefined,
  });

  const ref = useRef();

  const scrollPosition = useScrollPosition(ref);

  return (
    <>
      {fixed ? (
        children
      ) : (
        <Slide
          appear={false}
          direction="down"
          in={!trigger && (!sticky || scrollPosition === 0)}
        >
          {children}
        </Slide>
      )}
    </>
  );
}

function useScrollPosition(ref) {
  const [scrollPosition, setScrollPosition] = useState(0);

  useEffect(() => {
    const handleScroll = () => {
      setScrollPosition(window.scrollY);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return scrollPosition;
}
