import React, { useContext } from "react";
import {
  ContentPanel,
  ContentPanelTitle,
  Row,
} from "../component_library/layout";
import { Box, useMediaQuery, useTheme } from "@mui/material";
import {
  Paragraph,
  ParagraphBody,
  ParagraphTitle,
} from "../component_library/paragraph";
import { Localization } from "../component_library/localization";
import { InstagramButton, TikTokButton, XButton, YouTubeButton } from "./social_buttons";

export function ContactInfoSection() {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down("md"));

  return (
    <Box id="contacts">
      <ContentPanel color={theme.palette.background.paper}>
        <Row center={!isSmallScreen}>
          <Paragraph>
            <ParagraphBody>
              <TikTokButton sx={{ mr: 2, fontSize: '1rem' }} />
              <YouTubeButton sx={{ mr: 2 }} />
              <XButton sx={{ mr: 2 }} />
              <InstagramButton sx={{ mr: 2 }} />
            </ParagraphBody>
          </Paragraph>
          <Paragraph>
            <ParagraphTitle> {Localization.get("press_contact")} </ParagraphTitle>
            <ParagraphBody>
              press@jarsick.com
            </ParagraphBody>
          </Paragraph>
          <Paragraph>
            <ParagraphTitle> {Localization.get("support_contact")} </ParagraphTitle>
            <ParagraphBody>
              support@jarsick.com
            </ParagraphBody>
          </Paragraph>
        </Row>
      </ContentPanel>
    </Box>
  );
}
