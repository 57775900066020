import { Scale } from "@mui/icons-material";
import { Button, colors, Icon, IconButton, SvgIcon, useTheme } from "@mui/material";


export const JarsickWebsiteButton = ({ onClick, color = "white", variant="contained", size = "medium", sx, children }) => {

    const hoverStyle = {
        border: '2px solid',
        boxShadow: `0 0 .4rem white, 0 0 .4rem white, 0 0 2rem ${color}, inset 0 0 0.7rem ${color}`,
        textShadow: `0 0 .1rem white`,
    };

    const backgroundColor = (variant === 'outlined' || variant === 'text') ? 'rgb(0,0,0,0.3)' : color;

    return (
        <Button
            onClick={onClick}
            variant={variant}
            size={size}
            sx={{
                ...sx,
                backgroundColor: `${backgroundColor}`,
                border: `2px solid ${color}`,
                color: `white`,
                '&:hover': hoverStyle,
            }}>
            {children}
        </Button>
    );
}

export const JarsickWebsiteIconButton = ({ color, onClick, sx, children }) => {

    const theme = useTheme();
    const iconHoverStyle = {
        filter: `drop-shadow(0 0 2px #fff) drop-shadow(0 0 2px ${color || theme.palette.primary.main})`
    }

    return (
        <IconButton
            onClick={onClick}
            fontSize="large"
            sx={{
                ...sx
            }}
        >
            <SvgIcon
                color={color || theme.palette.primary.main}
                fontSize="large"
                sx={{
                    filter: 'none',
                    transition: 'all 0.3s ease-in-out',
                    '&:hover': iconHoverStyle
                }}
            >
                {children}
            </SvgIcon>
        </IconButton>
    );

};

