import { useTheme } from "@emotion/react";
import { ContentPanel, Row } from "../component_library/layout";
import { Paragraph, ParagraphBody, ParagraphTitle, toHTML } from "../component_library/paragraph";
import { JarsickWebsiteButton } from "../components/jarsick_website_button";
import { List, ListItem } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { DisasterBlasterWikiBanner } from "../components/disasterblaster_wiki_banner";
import { ContactInfoSection } from "../components/contact_info_section";
import { LocalActivity } from "@mui/icons-material";
import { Localization } from "../component_library/localization";
import { Helmet } from "react-helmet";

const backgroundColor = "#211132";

function IntroducionSection() {
    const theme = useTheme();
    const navigate = useNavigate();

    return (
        <ContentPanel color={backgroundColor} padding={"5rem"}>

            <Row>
                <Paragraph>
                    <ParagraphBody>
                        {Localization.get("db_wiki_welcome")}
                    </ParagraphBody>
                </Paragraph>
            </Row>
            <Row>
                <Paragraph>
                    <ParagraphTitle>
                        {Localization.get("db_wiki_intro_title")}
                    </ParagraphTitle>
                    <ParagraphBody>
                        {Localization.get("db_wiki_intro")}
                    </ParagraphBody>
                </Paragraph>
            </Row>
            <Row>
                <JarsickWebsiteButton
                    color={theme.palette.primary.main}
                    size="large"
                    onClick={() => navigate("/disasterblaster/wiki/cards")}
                >
                    {Localization.get("db_wiki_cards_button")}
                </JarsickWebsiteButton>
            </Row>
        </ContentPanel>
    );
}

function ExternalLinksSection() {
    return (
        <ContentPanel color={backgroundColor}>
            <Row center={false}>
                <Paragraph>
                    <ParagraphTitle>
                        {Localization.get("db_wiki_external_links_title")}
                    </ParagraphTitle>
                    <ParagraphBody>
                        <ul>
                            <li>
                                {toHTML(Localization.get("db_wiki_external_links_steam"))}
                            </li>
                            <li>
                                {toHTML(Localization.get("db_wiki_external_links_itchio"))}
                            </li>
                        </ul>
                    </ParagraphBody>
                </Paragraph>
            </Row>
        </ContentPanel>
    );
}

export function DisasterBlasterWikiHomePage() {
    return (
        <>
            <Helmet>
                <title>Disaster Blaster Wiki</title>
                <meta name="description" content="Welcome to the Disaster Blaster Wiki! This is a place where you can learn about the game, its mechanics, and the lore behind it." />
                <link rel="canonical" href="https://www.jarsick.com/#disasterblaster/wiki" />
            </Helmet>
            <DisasterBlasterWikiBanner />
            <IntroducionSection />
            <ExternalLinksSection />
            <ContactInfoSection />
        </>
    );
}