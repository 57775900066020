import { Box, Grow, useMediaQuery } from "@mui/material";
import { Col, ContentPanel, ContentPanelTitle, Row } from "../component_library/layout";
import { ContactInfoSection } from "../components/contact_info_section";
import { Image } from "../component_library/image";
import { Paragraph, ParagraphBody, ParagraphTitle, toHTML } from "../component_library/paragraph";
import { Localization } from "../component_library/localization";
import { Carousel } from "../component_library/carousel";
import { NewsSection } from "../components/news_section";
import { Helmet } from "react-helmet";
import { JarsickWebsiteButton, JarsickWebsiteIconButton } from "../components/jarsick_website_button";
import { useTheme } from "@emotion/react";
import { useNavigate } from "react-router-dom";
import { SpatialTracking } from "@mui/icons-material";
import AutoStoriesIcon from '@mui/icons-material/AutoStories';
import { TikTokButton, XButton, YouTubeButton } from "../components/social_buttons";
import GroupsIcon from '@mui/icons-material/Groups';

function DisasterBlasterParagraphTitle({ textColor = "white", borderColor = "white", backgroundColor = "black", children }) {
    return (
        <Box sx={{ marginBottom: "2rem", }}>
            <ParagraphTitle
                color={textColor}
                center={true}
                sx={{
                    border: "0.2rem solid #fff",
                    borderRadius: "1rem",
                    padding: "0.4em",
                    boxShadow: `0 0 .2rem #fff, 0 0 .2rem #fff, 0 0 1rem ${borderColor}, 0 0 0.4rem ${borderColor}, 0 0 1.4rem ${borderColor}, inset 0 0 0.7rem ${borderColor}`,
                    textShadow: `0 0 .2rem ${textColor}`,
                    backgroundColor: `${backgroundColor}`
                }}>
                {children}
            </ParagraphTitle>
        </Box>
    );
}

function TrailerSection() {

    return (
        <Box sx={{ marginTop: "5rem" }}>
            <ContentPanel backgroundImageSrc={"images/disaster-blaster-background.jpg"}>
                <Row weights={[7, 5]} >
                    <Grow in={true} timeout={1000}>
                        <iframe
                            width="100%"
                            height="360px"
                            src="https://www.youtube.com/embed/VGekBDYvndA?si=aOrrmXEk9qOmFql0"
                            title="YouTube video player"
                            frameborder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                            referrerpolicy="strict-origin-when-cross-origin"
                            allowfullscreen>
                        </iframe>
                    </Grow>
                    <Image src="images/disaster_blaster_logo.png" alt="Disaster Blaster Logo" width="100%" />
                </Row>
            </ContentPanel>
        </Box>
    );
}


function DisasterBlasterFeaturePanel({ backgroundColor, title, body, imageSrc, switchImageAndText = false }) {

    const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down("md"));

    const rowSpacing = 9;
    const rowBorderRadius = "1rem";
    const rowBoxShadow = "0 0 1rem #000, 0 0 2rem #000";
    const rowPadding = isSmallScreen ? "1rem" : "2rem";

    const titleBorderColor = "#fffA8f";
    const titleColor = "#ffffd4";
    const titleBackgroundColor = "#553676";

    const imageBorderRadius = "2rem";
    const imageBoxShadow = `0 0 1rem ${titleColor}, 0 0 2rem ${titleColor}`

    return (

        switchImageAndText ? (
            <Row
                sx={{
                    backgroundColor: backgroundColor,
                    borderRadius: rowBorderRadius,
                    boxShadow: rowBoxShadow,
                    padding: rowPadding,
                }}
            >
                <div>
                    <Image
                        src={imageSrc}
                        sx={{
                            borderRadius: imageBorderRadius,
                            boxShadow: imageBoxShadow,
                        }}
                    />
                </div>
                <Paragraph>
                    <DisasterBlasterParagraphTitle
                        borderColor={titleBorderColor}
                        textColor={titleColor}
                        backgroundColor={titleBackgroundColor}
                    >
                        {title}
                    </DisasterBlasterParagraphTitle>
                    <ParagraphBody center={isSmallScreen}>
                        {body}
                    </ParagraphBody>
                </Paragraph>
            </Row>
        ) : (
            <Row
                sx={{
                    backgroundColor: backgroundColor,
                    borderRadius: rowBorderRadius,
                    boxShadow: rowBoxShadow,
                    padding: rowPadding,
                }}
            >
                <Paragraph>
                    <DisasterBlasterParagraphTitle
                        borderColor={titleBorderColor}
                        textColor={titleColor}
                        backgroundColor={titleBackgroundColor}
                    >
                        {title}
                    </DisasterBlasterParagraphTitle>
                    <ParagraphBody center={isSmallScreen}>
                        {body}
                    </ParagraphBody>
                </Paragraph>
                <div>
                    <Image
                        src={imageSrc}
                        sx={{
                            borderRadius: imageBorderRadius,
                            boxShadow: imageBoxShadow,
                        }}
                    />
                </div>
            </Row>
        )
    );
}


function AboutGameSection() {

    const panelBackgroundColor = "#211132";
    const textBackgroundColor1 = "#72499f";
    const textBackgroundColor2 = "#a16bd5";
    const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down("md"));
    const SCREENSHOT_COUNT = 12;
    const theme = useTheme();

    return (
        <>
            <ContentPanel>
                <Row>
                    <Carousel>
                        {Array.from({ length: SCREENSHOT_COUNT }, (_, i) => (
                            <Image
                                src={`images/disasterblaster_ss${i + 1}.jpg`}
                                width="100%"
                            />
                        ))}
                    </Carousel>
                </Row>
            </ContentPanel>

            <ContentPanel backgroundImageSrc={"images/db_panel_gradient_background.jpg"}>
                <Col
                    weights={isSmallScreen ? [9, 3] : [7, 5]}
                    sx={{
                        backgroundColor: `rgba(0, 0, 0, .5)`,
                        padding: "2rem",
                        borderRadius: "1rem",
                        boxShadow: "0 0 1rem #000, 0 0 2rem #000",
                    }}>
                    <Paragraph>
                        <ParagraphTitle>
                            {toHTML(Localization.get("disaster_blaster_catchphrase"))}
                        </ParagraphTitle>
                        <ParagraphBody>
                            {toHTML(Localization.get("disaster_blaster_description"))}
                        </ParagraphBody>
                    </Paragraph>
                    <Paragraph>
                        <ParagraphTitle center={true}>
                            {toHTML(Localization.get("demo_try_call_to_action_title"))}
                        </ParagraphTitle>
                        <ParagraphBody center={true}>
                            {toHTML(Localization.get("demo_try_call_to_action"))}
                            <JarsickWebsiteButton
                                onClick={() => window.open("https://jarsick.itch.io/disaster-blaster-demo")}
                                size="small"
                                variant="text"
                                color={theme.palette.primary.main}
                                sx={{ width: "20%", margin: "20px" }}
                            >
                                <Image src="images/itchio-logo.png" />
                            </JarsickWebsiteButton>
                            <JarsickWebsiteButton
                                onClick={() => window.open("https://store.steampowered.com/app/3194600/Disaster_Blaster/")}
                                size="small"
                                variant="text"
                                color={theme.palette.primary.main}
                                sx={{ width: "20%", margin: "20px" }}
                            >
                                <Image src="images/steam-logo.png" />
                            </JarsickWebsiteButton>
                        </ParagraphBody>
                    </Paragraph>
                </Col>

            </ContentPanel>

            <ContentPanel color={panelBackgroundColor}>
                <DisasterBlasterFeaturePanel
                    backgroundColor={textBackgroundColor1}
                    title={Localization.get("disaster_blaster_build_title")}
                    body={Localization.get("disaster_blaster_build_description")}
                    imageSrc="gifs/db-store-build.gif"
                />
            </ContentPanel>

            <ContentPanel backgroundImageSrc={"images/db_panel_background.jpg"}>
                <DisasterBlasterFeaturePanel
                    backgroundColor={textBackgroundColor2}
                    title={Localization.get("disaster_blaster_destroy_title")}
                    body={Localization.get("disaster_blaster_destroy_description")}
                    imageSrc="gifs/db-store-destroy.gif"
                    switchImageAndText={true}
                />
            </ContentPanel>

            <ContentPanel color={panelBackgroundColor}>
                <DisasterBlasterFeaturePanel
                    backgroundColor={textBackgroundColor1}
                    title={Localization.get("disaster_blaster_discover_title")}
                    body={Localization.get("disaster_blaster_discover_description")}
                    imageSrc="gifs/db-store-discover.gif"
                />
            </ContentPanel >

            <ContentPanel backgroundImageSrc={"images/db_panel_background.jpg"}>
                <DisasterBlasterFeaturePanel
                    backgroundColor={textBackgroundColor2}
                    title={Localization.get("disaster_blaster_upgrade_title")}
                    body={Localization.get("disaster_blaster_upgrade_description")}
                    imageSrc="gifs/db-store-upgrade.gif"
                    switchImageAndText={true}
                />
            </ContentPanel>
        </>
    );
}

function CommunitySection() {
    const navigate = useNavigate();
    const theme = useTheme();
const backgroundColor = "#211233";
const textBackgroundColor = "#72499f";

    return (
        <ContentPanel backgroundImageSrc={"images/db_stars_background.jpg"}>
            <ContentPanelTitle>
                {Localization.get("join_community_title")}
            </ContentPanelTitle>
            <Row>
                <Paragraph>
                    <ParagraphBody>
                        {Localization.get("join_community_description")}
                    </ParagraphBody>
                </Paragraph>
            </Row>
            <Row>

                <Col weights={[6, 4, 2]} sx={{ px: "3rem" }} spacing={2}>
                    <AutoStoriesIcon sx={{ fontSize: 100, color: "white" }} />
                    <Paragraph>
                        <ParagraphBody center={true}>
                            {Localization.get("wiki_description")}
                        </ParagraphBody>
                    </Paragraph>
                    <JarsickWebsiteButton
                        onClick={() => navigate("/disasterblaster/wiki")}
                        color={theme.palette.primary.main}
                    >
                        {Localization.get("wiki_button")}
                    </JarsickWebsiteButton>
                </Col>
                <Col>
                    <GroupsIcon sx={{ fontSize: 100, color: "white" }} />
                    <Row>
                        <TikTokButton sx={{ mr: 2 }} />
                        <YouTubeButton sx={{ mr: 2 }} />
                        <XButton sx={{ mr: 2 }} />
                    </Row>
                    <></>
                </Col>
            </Row>
        </ContentPanel>
    );
}



export function DisasterBlasterPage() {
    return (
        <div>
            <Helmet>
                <title>Disaster Blaster</title>
                <meta name="description" content="Disaster Blaster is a roguelite card game where you build your own automatic weapon with crazy upgrading cards to decimate waves of enemies and create the most devastating arsenal in the universe. Increase damage, multiply bullets, make them explosive and electrifying, it's all up to you!" />
                <link rel="canonical" href="https://www.jarsick.com/#disasterblaster" />
            </Helmet>
            <TrailerSection />
            <AboutGameSection />
            <CommunitySection />
            <NewsSection backgroundColor={"#211233"} />
            <ContactInfoSection />
        </div>
    );
}
