import { JarsickWebsiteIconButton } from "./jarsick_website_button";
import { ReactComponent as TikTokIconSvg } from "../vectors/tiktok-icon.svg";
import { ReactComponent as XIconSvg } from "../vectors/x-icon.svg";
import { ReactComponent as InstagramIconSvg } from "../vectors/instagram-icon.svg";
import { ReactComponent as YouTubeIconSvg } from "../vectors/youtube-icon.svg";

export function TikTokButton({ sx, color }) {
    return (
        <JarsickWebsiteIconButton
            sx={{ ...sx }}
            color={color}
            onClick={() => window.open("https://www.tiktok.com/@disaster.blaster.game")}
        >
            <TikTokIconSvg width={"100%"} height={"100%"} />
        </JarsickWebsiteIconButton>
    );
}

export function YouTubeButton({ sx, color }) {
    return (
        <JarsickWebsiteIconButton
            sx={{ ...sx }}
            color={color}
            onClick={() => window.open("http://www.youtube.com/@jarsickgames6422")}
        >
            <YouTubeIconSvg width={"100%"} height={"100%"} />
        </JarsickWebsiteIconButton>
    );
}

export function XButton({ sx, color }) {
    return (
        <JarsickWebsiteIconButton
            sx={{ ...sx }}
            color={color}
            onClick={() => window.open("https://x.com/JarsickGames")}
        >
            <XIconSvg width={"100%"} height={"100%"} />
        </JarsickWebsiteIconButton>
    );
}

export function InstagramButton({ sx, color }) {
    return (
        <JarsickWebsiteIconButton
            sx={{ ...sx }}
            color={color}
            onClick={() => window.open("https://www.instagram.com/jarsick_official")}
        >
            <InstagramIconSvg width={"100%"} height={"100%"} />
        </JarsickWebsiteIconButton>
    );
}
